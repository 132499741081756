<template>
  <v-container>
    <v-row>
      <v-dialog ref="dialog" v-model="dialog" :return-value.sync="membershipType" persistent max-width="800px"
        @keydown.esc="close()" transition="dialog-bottom-transition">

        <v-stepper v-model="step">
          <v-stepper-header>
            <v-spacer />

            <v-stepper-step :complete="step > 1" step="1">
              Connectez-vous
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2"> Choix des options </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Valider la séance </v-stepper-step>

            <v-spacer />

            <PopupCloseButton @closeClick="close" />
          </v-stepper-header>

          <v-stepper-items>
            <div class="justify-center d-flex flex-column align-center mt-3">
              <div v-if="lesson" class="title">{{ lesson.activityName ? lesson.activityName : lesson.name }}</div>
              <div v-if="planning" class="title ml-2">
                <span class="">le {{ $moment(planning.dateBegin).format("dddd") }}</span><span class="ml-2">{{
                  $moment(planning.dateBegin).format("DD MMMM")
                }} à {{ $moment(planning.dateBegin).format("HH:mm") }}</span>
              </div>
            </div>

            <v-stepper-content step="1">
              <v-expansion-panels>

                <v-expansion-panel>
                  <v-expansion-panel-header class="title primary">
                    Je n'ai pas de compte
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-form ref="formRegister">
                      <v-container>
                        <v-row>
                          <v-text-field :label="$helpers.string.capitalizeI18N('first-name', {
                            required: true,
                          })
                            " v-model="account.firstName" name="firstName" prepend-icon="mdi-account" type="text"
                            :rules="$helpers.rule.notEmpty()" />
                        </v-row>
                        <v-row>
                          <v-text-field :label="$helpers.string.capitalizeI18N('last-name', {
                            required: true,
                          })
                            " v-model="account.lastName" name="lastName" prepend-icon="mdi-account" type="text"
                            :rules="$helpers.rule.notEmpty()" />
                        </v-row>
                        <v-row>
                          <v-text-field :label="$helpers.string.capitalizeI18N('email', {
                            required: true,
                          })
                            " v-model="account.email" name="email" prepend-icon="mdi-email" type="email"
                            :rules="$helpers.rule.notEmpty()" />
                        </v-row>
                        <v-row>
                          <v-text-field :type="showPassword ? 'text' : 'password'" :label="$helpers.string.capitalizeI18N('password', {
                            required: true,
                          })
                            " v-model="account.password" name="password" prepend-icon="mdi-lock"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword" :rules="$helpers.rule.notEmpty()" />
                        </v-row>
                        <v-row>
                          <v-select v-model="account.gender" :items="['m', 'f', 'o']"
                            :label="$helpers.string.capitalizeI18N('gender')" prepend-icon="mdi-gender-male-female">
                            <template v-slot:selection="data">
                              {{ getGenderLabel(data.item) }}
                            </template>
                            <template v-slot:item="data">
                              {{ getGenderLabel(data.item) }}
                            </template>
                          </v-select>
                        </v-row>
                        <v-row>
                          <v-text-field :label="$helpers.string.capitalizeI18N('phone-number')"
                            v-model="account.phoneNumber" type="phone" prepend-icon="mdi-phone"></v-text-field>
                        </v-row>
                        <v-row class="mt-8">
                          <v-btn color="deep-orange lighten-3" dark @click="handleRegister" :disabled="loading" block>
                            <span v-show="loading" class="spinner-border spinner-border-sm"></span>{{ $t("register") }}
                          </v-btn>
                        </v-row>

                        <v-row class="mt-8">
                          <small>* {{ $t("required-fields") }}</small>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="title primary">
                    J'ai déjà un compte
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container>
                      <v-form ref="formLogin">
                        <v-text-field :label="'e-mail ou code client'" v-model="account.login"
                          prepend-icon="mdi-account" />

                        <v-text-field v-model="account.password" :label="'mot de passe'" prepend-icon="mdi-lock"
                          type="password" @keydown.enter="handleLogin()" />

                        <v-btn color="primary" @click="handleLogin()" text>Se connecter</v-btn>
                      </v-form>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="justify-center d-flex">Aucune option disponible pour le moment</div>
              <div class="justify-center d-flex"><v-btn color="primary" @click="step = 3" text>suivant</v-btn></div>
            </v-stepper-content>

            <v-stepper-content step="3">

              <v-expansion-panels>
                <v-expansion-panel v-if="quickMembershipType" @click="selectQuickMembershipType()">
                  <v-expansion-panel-header class="title primary">
                    Réservation rapide ({{ quickMembershipType.priceVatIncluded > 0 ?
                      `${quickMembershipType.priceVatIncluded}
                    € à payer maintenant` :
                      'vous n\'avez rien à payer maintenant' }})
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="memberships && memberships.length > 0">
                  <v-expansion-panel-header class="title primary">
                    Utiliser un de mes abonnements
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-radio-group v-model="membership">
                      <v-list>
                        <v-list-item v-for="m in memberships" :key="m.id" link :value="m" @click="membership = m">
                          <v-list-item-icon>
                            <v-radio :value="m"></v-radio>
                          </v-list-item-icon>
                          <v-list-item-title>
                            <v-container>
                              <v-row align="center">
                                {{ m.membership_type.name }}
                                <v-spacer />
                                <v-chip v-if="m.membership_type.unit" text-color="white" color="orange">{{
                                  Number(m.totalUnit) }}/{{ m.membership_type.unit }}</v-chip>
                              </v-row>
                            </v-container>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-radio-group>
                    <v-btn :loading="loading" color="primary" @click="selectExistingMembership()">
                      continuer
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="membershipTypes && membershipTypes.length > 0">
                  <v-expansion-panel-header class="title primary">
                    Acheter un nouvel abonnement
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-sheet>
                      <v-radio-group v-model="membershipType">
                        <v-container v-for="m in membershipTypes" :key="m.id" link :value="m"
                          @click="membershipType = m" class="pointer" fluid>
                          <v-row align="center" no-gutters>
                            <v-col class="shrink">
                              <v-radio :value="m"></v-radio>
                            </v-col>
                            <v-col class="grow">
                              {{ m.name }}
                            </v-col>
                            <v-col class="grow text-right">
                              <v-chip text-color="white" color="orange">
                                <span v-if="m.unit">{{ m.unit }} unités</span>
                                <span v-else>unités illimitées</span></v-chip>
                            </v-col>
                            <v-col cols="2" class="text-right">
                              <span v-if="m.priceVatIncluded">
                                {{ m.priceVatIncluded }} €</span>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-radio-group>

                    </v-sheet>

                    <v-container>
                      <v-row align="end" justify="end">
                        <v-btn @click="close" color="light-grey"> annuler </v-btn>
                        <v-btn color="primary" @click="bookingMode = 'new-membership'; step = 4"
                          :disabled="!membershipType" class="ml-2">
                          Continuer
                          <v-icon class="ml-2">mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <div class="d-flex justify-center" v-if="membershipTypes && membershipTypes.length == 0">Aucun type
                d'abonnement n'est
                disponible actuellement. Veuillez réessayer plus tard.</div>

            </v-stepper-content>

            <v-stepper-content step="4">

              <v-sheet v-if="bookingMode == 'quick-membership' && membershipType">
                <!-- quick membership -->
                <!-- {{ membershipType }} -->

                <v-radio-group v-model="paymentTypeId"
                  v-if="paymentTypes.payment_types && paymentTypes.payment_types.length > 0">
                  <v-container v-for="pt in paymentTypes.mollie_methods" :key="'mollie.' + pt.id" link :value="pt.id"
                    @click="paymentTypeId = 'mollie.' + pt.id" class="pointer" fluid>
                    <v-row align="center" no-gutters>
                      <v-col class="shrink">
                        <v-radio :value="'mollie.' + pt.id"></v-radio>
                      </v-col>
                      <v-col class="shrink">
                        <v-img :src="pt.image" />
                      </v-col>
                      <v-col class="grow ml-2 font-weight-bold">
                        {{ pt.description }}
                      </v-col>
                      <v-col class="grow text-right">
                        <span v-if="pt.id == 'bancontact'">
                          Payer en toute sécurité vers un compte Belge avec
                          Bancontact
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-radio-group>
                <!-- 
                <v-btn :loading="loading" color="primary" @click="saveAndClose" class="ml-2">
                  confirmer
                  <v-icon class="ml-2">mdi-check</v-icon>
                </v-btn> -->
              </v-sheet>

              <v-sheet v-if="bookingMode == 'existing-membership' && membership">
                existing membership {{ membership }}
              </v-sheet>

              <v-sheet v-if="bookingMode == 'new-membership' && membershipType">

                <div v-if="membershipType.priceVatIncluded">
                  {{ membershipType.name }}
                  <span v-if="membershipType.priceVatIncluded">{{ membershipType.priceVatIncluded }} €</span>
                  <v-radio-group v-model="paymentTypeId" v-if="paymentTypes.payment_types &&
                    paymentTypes.payment_types.length > 0
                  ">
                    <v-container v-for="pt in paymentTypes.mollie_methods" :key="'mollie.' + pt.id" link :value="pt.id"
                      @click="paymentTypeId = 'mollie.' + pt.id" class="pointer" fluid>
                      <v-row align="center" no-gutters>
                        <v-col class="shrink">
                          <v-radio :value="'mollie.' + pt.id"></v-radio>
                        </v-col>
                        <v-col class="shrink">
                          <v-img :src="pt.image" />
                        </v-col>
                        <v-col class="grow ml-2 font-weight-bold">
                          {{ pt.description }}
                        </v-col>
                        <v-col class="grow text-right">
                          <span v-if="pt.id == 'bancontact'">
                            Payer en toute sécurité vers un compte Belge avec
                            Bancontact
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>

                    <div v-for="pt in paymentTypes.payment_types" :key="pt.id" link :value="pt.id"
                      @click="paymentTypeId = pt.id" class="pointer">
                      <v-container v-if="pt.id != 'mollie'" fluid>
                        <v-row align="center" no-gutters>
                          <v-col class="shrink">
                            <v-radio :value="pt.id"></v-radio>
                          </v-col>
                          <v-col class="grow font-weight-bold">
                            {{ getPaymentTypeLabel(pt) }}
                          </v-col>
                          <v-col class="grow text-right">
                            {{ getPaymentTypeDescription(pt) }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-radio-group>
                  <div v-else>
                    Aucune méthode de paiement n'est disponible pour le moment.
                  </div>
                </div>
                <div v-else>Abonnement gratuit, vous n'avez rien à payer.</div>
              </v-sheet>

              <v-container>
                <v-row align="end" justify="end">
                  <v-btn @click="close" color="light-grey"> annuler </v-btn>
                  <v-btn ref="saveButton" :loading="loading" :disabled="!canSave" color="primary" @click="saveAndClose"
                    class="ml-2">
                    confirmer
                    <v-icon class="ml-2">mdi-check</v-icon>
                  </v-btn>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-container>
              <v-row>
                <v-col class="text-body-2 justify-center align-center d-flex grey--text text--darken-2 pointer"
                  @click="openTerms()">
                  <v-icon left small>mdi-information-outline</v-icon>
                  En continuant, vous acceptez les termes et conditions</v-col>
              </v-row>
            </v-container>
          </v-stepper-items>
        </v-stepper>

      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import PopupCloseButton from '../Admin/PopupCloseButton.vue';

export default {
  props: {},
  data() {
    return {
      account: {
        login: null,
        password: null,
        firstName: null,
        lastName: null,
        email: null,
        gender: null,
        phoneNumber: null,
      },
      bookingMode: null,
      showPassword: false,
      client: null,
      quickMembershipType: null,
      membership: null,
      memberships: [],
      membershipType: null,
      membershipTypes: [],
      paymentTypeId: null,
      paymentTypes: [],
      dialog: false,
      loading: false,
      // planningId: null,
      planning: null,
      lesson: null,
      step: 1,
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
    canSave() {
      if (!this.membershipType) return false;

      if (this.paymentTypeId) return true;

      if (Number(this.membershipType.priceVatIncluded) == 0) return true;

      return false;
    },
  },
  methods: {
    openTerms() {
      window.open(`https://www.monplanning.be/${this.organizationUrl}/terms`, '_blank')
    },
    async handleLogin() {
      if (!this.$refs.formLogin.validate())
        return;
      if (this.account.login && this.account.password) {
        this.loading = true;
        this.$store.dispatch("publicorganizationauth/login", this.account).then(async () => {
          this.loading = false;
          this.client = await this.$store
            .dispatch("publicorganizationauth/fetchClient")
            .catch(() => { });
          if (this.client != null) {
            await this.clientChanged();
            this.step = 3;
          }
        }, (error) => {
          this.loading = false;
          switch (error.response?.data?.code) {
            case 'POL.password-undefined':
              this.$helpers.snackbar.showSuccess(this.$t('errors.' + error.response?.data?.code));
              break;
            default: this.$helpers.snackbar.handleError(error);
          }
        });
      }
    },
    async handleRegister() {
      if (!this.$refs.formRegister.validate())
        return;
      this.$store.dispatch("publicorganizationauth/register", this.account).then((data) => {
        this.$store
          .dispatch("publicorganizationauth/login", {
            login: data.login,
            password: this.account.password,
          })
          .then(async () => {
            this.client = await this.$store
              .dispatch("publicorganizationauth/fetchClient")
              .catch(() => { });
            if (this.client != null) {
              await this.clientChanged();
              this.step = 3;
            }
          }, (error) => {
            this.loading = false;
            this.$helpers.snackbar.handleError(error);
          });
      }, (error) => {
        this.loading = false;
        this.$helpers.snackbar.handleError(error);
      });
    },
    getGenderLabel(gender) {
      switch (gender) {
        case "o":
          return this.$helpers.string.capitalizeI18N("gender-o");
        case "m":
          return this.$helpers.string.capitalizeI18N("gender-m");
        case "f":
          return this.$helpers.string.capitalizeI18N("gender-f");
        default:
          return gender;
      }
    },
    getPaymentTypeLabel(paymentType) {
      switch (paymentType.id) {
        case "cash":
          return "Payer en espèces";
        case "sepa":
          return "Virement bancaire";
        default:
          return null;
      }
    },
    getPaymentTypeDescription(paymentType) {
      switch (paymentType.id) {
        case "cash":
          return "Payer plus tard sur place";
        case "sepa":
          return "Effectuer un virement sur notre compte bancaire";
        default:
          return null;
      }
    },
    close() {
      this.dialog = false;
      this.resetPopup();
      this.$emit("onCloseClicked");
    },
    async open(planning) {
      this.resetPopup();

      console.log('open', planning)

      this.planning = planning;
      this.lesson = this.planning.lesson;
      this.quickMembershipType = this.lesson.membership_type;

      this.client = await this.$store
        .dispatch("publicorganizationauth/fetchClient")
        .catch(() => { });

      if (this.client != null) {
        await this.clientChanged();
        this.step = 3;
      }

      this.dialog = true;
    },
    async clientChanged() {
      await this.retrieveMemberships();
      await this.retrieveMembershipTypes();
      await this.retrievePaymentTypes();

      if (this.memberships.length == 1) {
        this.membership = this.memberships[0];
      }

      if (this.membershipTypes.length == 1) {
        this.membershipType = this.membershipTypes[0];
      }

      if (this.paymentTypes.length == 1) {
        if (this.paymentTypes[0].id != "mollie") {
          this.paymentTypeId = this.paymentTypes[0].id;
        }
      }

      console.dir(this.paymentTypes);
    },
    async retrieveMembershipTypes() {
      const membershipTypes = await this.$services.publicorganization.getMembershipTypes(this.organizationUrl,
        // this.planningId
        this.planning.id);
      this.membershipTypes = membershipTypes.data;
    },
    async retrievePaymentTypes() {
      const paymentTypes = await this.$services.publicorganization.getPaymentTypes(this.organizationUrl,
        // this.planningId
        this.planning.id);
      this.paymentTypes = paymentTypes.data;
    },
    async retrieveMemberships() {
      const memberships = await this.$services.publicorganization.getPlanningMemberships(this.organizationUrl, this.planning.id);
      this.memberships = memberships.data;
    },
    async saveAndClose() {
      try {
        this.loading = true;
        // if (!this.$refs.form.validate()) return;
        const registrationPlanning = await this.$services.publicorganization.bookPlanning(this.organizationUrl, this.planning.id, this.bookingMode, {
          membershipId: this.membership?.id,
          membershipTypeId: this.membershipType?.id,
          paymentTypeId: this.paymentTypeId,
        });

        if (registrationPlanning.data.paymentUrl) {
          window.location.href = registrationPlanning.data.paymentUrl;
          return;
        }

        console.log("registrationPlanning", registrationPlanning.data);
        this.$helpers.snackbar.showSuccess("Réservation confirmée !");
        this.$emit("onSaveClicked", registrationPlanning);

        this.dialog = false;
      }
      catch (error) {
        this.$helpers.snackbar.handleError(error);
      }
      finally {
        this.loading = false;

        this.resetPopup();
      }
    },
    resetPopup() {
      this.step = 1;
      this.membership = null;
      this.membershipType = null;
      this.paymentTypeId = null;
      this.bookingMode = null;
    },
    async selectQuickMembershipType() {
      this.bookingMode = 'quick-membership';
      this.membershipType = this.quickMembershipType;
      if (this.membershipType.priceVatIncluded > 0) {
        this.step = 4;
      }
      else {
        // book membership and planning
        await this.saveAndClose();
      }
    },
    async selectExistingMembership() {
      this.bookingMode = 'existing-membership';
      // this.step = 4;
      await this.saveAndClose();
    }
  },
  components: { PopupCloseButton }
};
</script>