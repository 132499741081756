<template>
  <v-container>
    <v-row>
      <v-dialog
        ref="dialog"
        v-model="dialog"
        :return-value.sync="membership"
        persistent
        max-width="800px"
        @keydown.esc="close()"
        :fullscreen="$helpers.display.mobileDialog"
        :hide-overlay="$helpers.display.mobileDialog"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-card-title>
            Choississez l'abonnement à utiliser pour cette
            réservation</v-card-title
          >
          <v-card-text>
            <v-radio-group
              v-model="membership"
              v-if="memberships && memberships.length > 0"
            >
              <v-list>
                <v-list-item
                  v-for="m in memberships"
                  :key="m.id"
                  link
                  :value="m"
                  @click="membership = m"
                >
                  <v-list-item-icon>
                    <v-radio :value="m"></v-radio>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <v-container>
                      <v-row align="center">
                        {{ m.membership_type.name }}
                        <v-spacer />
                        <v-chip
                          v-if="m.membership_type.unit"
                          text-color="white"
                          color="orange"
                          >{{ Number(m.totalUnit) }}/{{
                            m.membership_type.unit
                          }}</v-chip
                        >
                      </v-row>
                    </v-container>
                  </v-list-item-title></v-list-item
                ></v-list
              >
            </v-radio-group>
            <div v-else>Vous n'avez aucun abonnement pour le moment.</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <CardActionButton @click="close" type="close" />
            <CardActionButton
              @click="saveAndClose"
              type="save"
              ref="saveButton"
            /> -->
            <v-btn
              @click="close"
              color="light-grey"
              v-if="memberships && memberships.length > 0"
            >
              annuler
            </v-btn>
            <v-btn
              ref="saveButton"
              :loading="loading"
              color="primary"
              @click="saveAndClose"
              v-if="memberships && memberships.length > 0"
            >
              confirmer
            </v-btn>
            <v-btn
              color="primary"
              :to="$helpers.router.getOrganizationLink('my-account')"
              v-else
            >
              voir mon compte
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import PublicOrganizationService from "@/services/publicorganizationservice";

export default {
  props: {},
  data() {
    return {
      membership: null,
      memberships: [],
      dialog: false,
      loading: false,
      planningId: null,
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    async open(planningId) {
      this.planningId = planningId;

      await this.retrieveMemberships();

      if (this.memberships.length == 1) {
        this.membership = this.memberships[0];
      }

      this.dialog = true;
    },
    async retrieveMemberships() {
      const memberships = await PublicOrganizationService.getPlanningMemberships(
        this.organizationUrl,
        this.planningId
      );

      this.memberships = memberships.data;
    },
    async saveAndClose() {
      try {
        this.loading = true;

        // if (!this.$refs.form.validate()) return;

        this.$emit("onSaveClicked", this.membership);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>