<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <WeekNavigator
          :date="date"
          :endDate="endDate"
          @previousWeek="previousWeek()"
          @thisWeek="thisWeek()"
          @nextWeek="nextWeek()"
        />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <v-card
          v-for="date in getDatesBetweenDates()"
          :key="date.toISOString()"
          class="mb-5"
        >
          <v-card-title
            :class="isDateDisabled(date) ? 'grey--text text--lighten-1' : ''"
          >
            <v-container>
              <v-row no-gutters dense align="center">
                <span class="">{{ $moment(date).format("dddd") }}</span
                ><span class="ml-2 body-1">{{
                  $moment(date).format("DD MMMM")
                }}</span>
              </v-row>
            </v-container></v-card-title
          >
          <v-card-text v-if="getPlanningForDate(date).length > 0">
            <OrganizationPlanningItems
              :plannings="getPlanningForDate(date)"
              @planningChanged="retrieveAll()"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <WeekNavigator
          :date="date"
          :endDate="endDate"
          @previousWeek="previousWeek()"
          @thisWeek="thisWeek()"
          @nextWeek="nextWeek()"
          position="top"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrganizationPlanningItems from "@/components/PublicOrganization/OrganizationPlanningItems";
import WeekNavigator from "@/components/PublicOrganization/WeekNavigator";

export default {
  components: {
    OrganizationPlanningItems,
    WeekNavigator,
  },
  data() {
    return {
      planning: [],
      date: null,
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
    endDate() {
      const endDate = new Date(this.date);
      endDate.setDate(endDate.getDate() + 6);

      return endDate;
    },
  },
  async mounted() {
    if (this.$route.query.date) {
      this.date = this.getMonday(this.$route.query.date);
    } else {
      this.thisWeek();
    }
  },
  watch: {
    date: async function (val) {
      const monday = this.getMonday(val);

      const path =
        this.$route.path +
        "?date=" +
        encodeURIComponent(monday.toISOString().substr(0, 10));

      if (this.$route.fullPath !== path) this.$router.push(path);

      await this.retrieveAll(monday);
    },
    "$store.state.publicorganizationauth.client": function () {
      this.date =  new Date(this.date);
    },
  },
  methods: {
    isDateDisabled(date) {
      const momentDate = this.$moment(date);
      momentDate.set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

      return momentDate < this.$moment(new Date());
    },

    getPlanningForDate(date) {
      const planning = this.planning.filter(
        (p) =>
          new Date(p.dateBegin).toISOString().substr(0, 10) ===
          date.toISOString().substr(0, 10)
      );
      // planning.sort(
      //   (a, b) => this.$moment(a.dateBegin) - this.$moment(b.dateBegin)
      // );

      return planning;
    },
    getDatesBetweenDates() {
      const endDate = this.endDate;

      let dates = [];
      //to avoid modifying the original date
      const theDate = new Date(this.date);
      while (theDate < endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
      }
      dates = [...dates, endDate];
      return dates;
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    previousWeek() {
      const previousDate = new Date(this.date);
      previousDate.setDate(previousDate.getDate() - 7);

      this.date = this.getMonday(previousDate);
    },
    thisWeek() {
      this.date = this.getMonday(new Date());
    },
    nextWeek() {
      const previousDate = new Date(this.date);
      previousDate.setDate(previousDate.getDate() + 7);

      this.date = this.getMonday(previousDate);
    },
    async retrieveAll(date) {
      try {
        const client = await this.$store
          .dispatch("publicorganizationauth/fetchClient")
          .catch(() => {});

        date = date ?? this.date;

        const planning = await this.$services.publicorganization.getAllPlanning(
          this.organizationUrl,
          client != null,
          date.toISOString().substr(0, 10)
        );

        this.planning = planning.data;

        console.dir(this.planning);
      } catch (error) {
        console.dir(error);
      }
    },
  },
};
</script>