<template>
  <div class="text-center" v-if="$helpers.display.isMobile">
    <div class="text-center mb-3 font-weight-medium" v-if="date && position == 'top'">
      {{ $moment(date).format("DD MMMM") }} -
      {{ $moment(endDate).format("DD MMMM YYYY") }}
    </div>
    <v-btn-toggle block class="d-flex flex-grow-1">
      <v-btn @click="previousWeek()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn class="flex-grow-1" @click="thisWeek()"> cette semaine </v-btn>
      <v-btn @click="nextWeek()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-btn-toggle>
    <div class="text-center mt-3 font-weight-medium" v-if="date && position == 'bottom'">
      {{ $moment(date).format("DD MMMM") }} -
      {{ $moment(endDate).format("DD MMMM YYYY") }}
    </div>
  </div>
  <div v-else>
    <div class="text-center mb-3 font-weight-medium" v-if="date && position == 'top'">
      {{ $moment(date).format("DD MMMM") }} -
      {{ $moment(endDate).format("DD MMMM YYYY") }}
    </div>

    <v-btn-toggle block class="d-flex flex-grow-1">
      <v-btn class="pl-6 pr-6" @click="previousWeek()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn class="pl-10 pr-10 flex-grow-1" @click="thisWeek()"> cette semaine </v-btn>
      <v-btn class="pl-6 pr-6" @click="nextWeek()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-btn-toggle>

    <div class="text-center mt-3 font-weight-medium" v-if="date && position == 'bottom'">
      {{ $moment(date).format("DD MMMM") }} -
      {{ $moment(endDate).format("DD MMMM YYYY") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: Date,
    endDate: Date,
    position: {
      type: String,
      default: 'bottom',
    }
  },
  methods: {
    previousWeek() {
      this.$emit("previousWeek");
    },
    thisWeek() {
      this.$emit("thisWeek");
    },
    nextWeek() {
      this.$emit("nextWeek");
    },
  },
};
</script>