<template>
  <div>
    <!-- <v-list>
    <v-list-item v-for="planning in plannings" :key="planning.id">
      <v-list-item-title> -->
    <v-container v-for="planning in plannings" :key="planning.id">
      <v-row :class="isPastPlanning(planning) || planning.canceled ? 'grey--text text--lighten-1' : ''" align="center">
        <v-col class="text-body-1 font-weight-medium shrink mr-4 pointer" @click="toggleShowDetails(planning)">{{
      $moment(planning.dateBegin).format("HH:mm") }}</v-col>
        <v-col class="grow pointer" @click="toggleShowDetails(planning)">
          <span class="text-body-1 font-weight-medium primary--text">{{
      getPlanningName(planning)
    }}</span> <v-icon right small color="primary">{{ showPlanningDetails.includes(planning.id) ?
      'mdi-chevron-up'
      : 'mdi-chevron-down' }}</v-icon></v-col>
        <v-col class="text-body-1 grow text-right mr-4">{{
      getUserName(planning)
    }}</v-col>
        <v-col class="text-right">
          <!-- <v-row align="end" justify="end"> -->
          <!-- can book -->
          <span v-if="planning.canceled">
            <v-btn disabled>{{ 'annulé'.toUpperCase() }}</v-btn>
          </span>
          <span v-else-if="!planning.planningMembershipId">
            <v-btn color="deep-orange lighten-3" dark :disabled="!canBook(planning)" @click="bookPlanning(planning)">{{
      isFull(planning) ? "complet" : "réserver" }}</v-btn>
          </span>
          <!-- can cancel -->
          <span v-else>
            <v-btn color="success" :disabled="!canUnbook(planning)" @click="unbookPlanning(planning)">annuler<v-icon
                class="ml-2">mdi-close-circle</v-icon></v-btn>
          </span>
          <!-- </v-row> -->
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row :class="isPastPlanning(planning) || planning.canceled ? 'grey--text text--lighten-1' : ''"
          v-show="showPlanningDetails.includes(planning.id)">
          <v-container>
            <v-row no-gutters>
              <v-col class="shrink" md="2" sm="4">Horaires</v-col>
              <v-col :class="$helpers.display.isMobile ? 'text-right' : ''">{{
      $moment(planning.dateBegin).format("HH:mm")
    }} -
                {{ $moment(planning.dateEnd).format("HH:mm") }}</v-col>
            </v-row>
            <v-row no-gutters v-if="getUserName(planning)">
              <v-col class="shrink" md="2" sm="4">Donné par</v-col>
              <v-col :class="$helpers.display.isMobile ? 'text-right' : ''">{{
      getUserName(planning)
    }}</v-col>
            </v-row>

            <v-row no-gutters v-if="planning.lesson.site && planning.lesson.site.address">
              <v-col class="shrink" md="2" sm="4">Adresse</v-col>
              <v-col :class="$helpers.display.isMobile ? 'text-right' : ''">{{
      getAddress(planning)
    }}</v-col>
            </v-row>
            <v-row no-gutters v-if="getRemainingUnit(planning)">
              <v-col class="shrink" md="2" sm="4">Places</v-col>
              <v-col :class="$helpers.display.isMobile ? 'text-right' : ''">{{ getRemainingUnit(planning) }} places
                disponibles</v-col>
            </v-row>
            <v-row class="mt-4 text-wrap" no-gutters v-if="planning.lesson.description">
              <v-col v-html="planning.lesson.description"></v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-expand-transition>
    </v-container>
    <!-- </v-list-item-title>
    </v-list-item> -->

    <MembershipSelection ref="membershipSelection" @onSaveClicked="membershipSelected" />

    <BookingPopup ref="bookingPopup" @onSaveClicked="membershipSelected" />

    <!-- </v-list> -->
  </div>
</template>

<script>
import MembershipSelection from "@/components/PublicOrganization/MembershipSelection";
import BookingPopup from "./BookingPopup.vue";

export default {
  components: {
    MembershipSelection,
    BookingPopup,
  },
  props: {
    plannings: Array,
  },
  data() {
    return {
      showPlanningDetails: [],
      organization: null,
    };
  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  methods: {
    getPlanningName(planning) {
      return planning.lesson?.activityName?.trim().length > 0 ? planning.lesson?.activityName : planning.lesson?.name;
    },
    getAddress(planning) {
      const address = planning.lesson.site.address;
      let planningAddress = "";
      if (address) {
        if (address.addressLine1) planningAddress += `${address.addressLine1}`;

        if (address.addressLine2)
          planningAddress += ` - ${address.addressLine2}`;

        if (address.zipCode || address.city)
          planningAddress += `, ${address.zipCode || ""} ${address.city || ""
            }`.trim();

        if (address.country) {
          planningAddress += ` - ${address.country.nameFr || ""}`;
        }
      }

      return planningAddress;
    },
    toggleShowDetails(planning) {
      if (this.showPlanningDetails.includes(planning.id))
        this.showPlanningDetails.splice(
          this.showPlanningDetails.indexOf(planning.id),
          1
        );
      else this.showPlanningDetails.push(planning.id);
    },
    async bookPlanning(planning) {
      try {
        this.$refs.bookingPopup.open(planning);

        // const client = await this.$store
        //   .dispatch("publicorganizationauth/fetchClient")
        //   .catch(() => {});

        // if (client == null) {
        //   this.$router.push({
        //     name: "OrganizationLogin",
        //     params: {
        //       organizationUrl: this.organizationUrl,
        //     },
        //     query: {
        //       next: this.$route.fullPath,
        //     },
        //   });
        //   return;
        // }

        // this.$refs.membershipSelection.open(planning.id);
      } catch (error) {
        console.dir(error);
      }
    },
    async unbookPlanning(planning) {
      try {
        var confirm = await this.$root.$confirm(
          this.$helpers.string.capitalizeI18N("confirmation"),
          this.$t("planning.unbook.message")
        );

        if (!confirm) return;

        await this.$services.publicorganization.unbookPlanningMembership(
          this.organizationUrl,
          planning.planningMembershipId
        );

        this.$emit("planningChanged", planning);
      } catch (error) {
        console.dir(error);
      }
    },
    isFull(planning) {
      return planning.lesson.maximumUnit
        ? parseInt(planning.totalUnit) >= parseInt(planning.lesson.maximumUnit)
        : false;
    },
    canBook(planning) {
      if (this.isFull(planning)) return false;

      if (planning.planningMembershipId) return false;

      if (this.isPastPlanning(planning)) return false;

      if (this.organization?.nbHourBooking) {
        var duration = this.$moment.duration(
          this.$moment(planning.dateBegin).diff(this.$moment(new Date()))
        );

        if (duration.asHours() <= this.organization.nbHourBooking) return false;
      }

      return true;
    },
    canUnbook(planning) {
      if (!planning.planningMembershipId) return false;

      if (this.isPastPlanning(planning)) return false;

      if (this.organization?.nbHourCancellation) {
        var duration = this.$moment.duration(
          this.$moment(planning.dateBegin).diff(this.$moment(new Date()))
        );

        if (duration.asHours() <= this.organization.nbHourCancellation)
          return false;
      }

      return true;
    },
    isPastPlanning(planning) {
      return this.$moment(planning.dateBegin) < this.$moment(new Date());
    },
    getRemainingUnit(planning) {
      return planning.lesson.maximumUnit
        ? parseInt(planning.lesson.maximumUnit) -
        parseInt(planning.totalUnit ?? 0)
        : null;
    },
    getUserName(planning) {
      return planning.user?.firstName;
    },
    async membershipSelected(planning) {

      console.dir(planning);

      this.$emit("planningChanged", planning);
    },
  },
  async mounted() {
    try {
      const organization = await this.$services.publicorganization.get(
        this.organizationUrl
      );

      this.organization = organization.data;
    } catch (error) {
      console.dir(error);
    }
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>